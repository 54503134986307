import React from "react"
import {
  greenBackground,
  grey,
  whiteBackground,
  whiteColor,
  blackColor,
} from "../styles"
import { CookieList } from "./cookie-list"
import { Cookie, getCookie, setCookie } from "../cookies"

const Button = ({ style = {}, children, ...props }) => (
  <button
    style={{
      border: `1px solid ${grey}`,
      marginLeft: "5px",
      marginRight: "5px",
      padding: "5px",
      ...style,
    }}
    {...props}
  >
    {children}
  </button>
)

export class CookieConsent extends React.Component {
  statisticsCheckbox = null
  state = {
    isCookieDetailsShown: false,
    isLoading: true,
  }

  componentDidMount() {
    this.setState({ isLoading: false })
  }

  render() {
    const { isLoading } = this.state
    const isCookieConsentAccepted = getCookie(Cookie.NECESSARY)
    if (isLoading || isCookieConsentAccepted) {
      return <></>
    }

    const { isCookieDetailsShown } = this.state

    return (
      <div
        style={{
          ...blackColor,
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 1000,
          backgroundColor: "rgba(0, 0, 0, 0.85)",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            marginLeft: "1rem",
            marginRight: "1rem",
            maxWidth: "42rem",
            backgroundColor: "white",
            padding: "1rem",
            overflowY: "auto",
            maxHeight: "80%",
          }}
        >
          <h2>This website uses cookies</h2>
          We use cookies to personalise content and to analyze our traffic.
          Select which categories of cookies you would like to allow.
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>
                <label>
                  <input type="checkbox" disabled checked></input> Necessary
                  cookies
                </label>
              </span>
              <span>
                <label>
                  <input
                    type="checkbox"
                    ref={el => (this.statisticsCheckbox = el)}
                  ></input>{" "}
                  Statistics cookies
                </label>
              </span>
            </div>
          </div>
          <Button
            style={{ ...whiteBackground, border: "none" }}
            onClick={() =>
              this.setState({ isCookieDetailsShown: !isCookieDetailsShown })
            }
          >
            {!isCookieDetailsShown && <span>&#9660; Show cookie details</span>}
            {isCookieDetailsShown && <span>&#9650; Hide cookie details</span>}
          </Button>
          {isCookieDetailsShown && <CookieList></CookieList>}
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={() => {
                setCookie(Cookie.NECESSARY, "true")
                this.forceUpdate()
              }}
              style={whiteBackground}
            >
              Allow only necessary
            </Button>
            <Button
              onClick={() => {
                setCookie(Cookie.NECESSARY, "true")
                if (this.statisticsCheckbox?.checked) {
                  setCookie(Cookie.STATISTICS, "true")
                }
                this.forceUpdate()
              }}
              style={whiteBackground}
            >
              Allow selected
            </Button>
            <Button
              onClick={() => {
                setCookie(Cookie.NECESSARY, "true")
                setCookie(Cookie.STATISTICS, "true")
                this.forceUpdate()
              }}
              style={{ ...greenBackground, ...whiteColor }}
            >
              Allow all
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export const yellow = "#f1c40f"
export const green = "#27ae60"
export const white = "white"
export const grey = "#95a5a6"
export const black = "black"

export const yellowColor = { color: yellow }
export const whiteColor = { color: white }
export const blackColor = { color: black }

export const yellowBackground = { backgroundColor: yellow }
export const whiteBackground = { backgroundColor: white }
export const greenBackground = { backgroundColor: green }

import React from "react"
import { Cookie } from "../cookies"

const border = { border: "1px solid black" }
const padding = { padding: 5 }

const Th = ({ children }) => (
  <th style={{ ...border, ...padding }}>{children}</th>
)

const Td = ({ children }) => (
  <td style={{ ...border, ...padding }}>{children}</td>
)

const Table = ({ items }) => (
  <table style={{ ...border, borderCollapse: "collapse", width: "100%" }}>
    <thead style={{ textAlign: "left" }}>
      <tr>
        <Th>Name</Th>
        <Th>Retention</Th>
        <Th>Description</Th>
      </tr>
    </thead>
    <tbody>
      {items.map(({ name, retention, description }) => (
        <tr key={name}>
          <Td>{name}</Td>
          <Td>{retention}</Td>
          <Td>{description}</Td>
        </tr>
      ))}
    </tbody>
  </table>
)

export const CookieList = () => (
  <div>
    <h4>Necessary cookies</h4>
    <Table
      items={[
        {
          name: Cookie.NECESSARY,
          retention: "2 years",
          description:
            "Indicates whether the user selected the necessary cookies.",
        },
        {
          name: Cookie.STATISTICS,
          retention: "2 years",
          description:
            "Indicates whether the user selected the statistics cookies.",
        },
      ]}
    ></Table>
    <h4>Statistics cookies</h4>
    <Table
      items={[
        {
          name: Cookie.GOOGLE_ANALYTICS,
          retention: "2 years",
          description:
            "Registers a unique ID that is used to generate statistical data on how the visitor uses the website. (Google Analytics)",
        },
        {
          name: Cookie.GOOGLE_ANALYTICS_GAT,
          retention: "1 day",
          description: "Used by Google Analytics to throttle request rate.",
        },
        {
          name: Cookie.GOOGLE_ANALYTICS_GID,
          retention: "1 day",
          description:
            "Registers a unique ID that is used to generate statistical data on how the visitor uses the website. (Google Analytics)",
        },
      ]}
    ></Table>
  </div>
)

import React from "react"
import { Link } from "gatsby"
import { CookieConsent } from "./cookie-consent"

export const Layout = ({ location, siteMetadata, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const { title, siteUrl } = siteMetadata
  let header

  if (location.pathname === rootPath) {
    header = (
      <h1>
        <Link to={"/"}>{title}</Link>
      </h1>
    )
  } else {
    header = (
      <h2>
        <Link to={"/"}>{title}</Link>
      </h2>
    )
  }

  const shortUrl = siteUrl.replace("https://", "")
  return (
    <div
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "42rem",
        padding: "2.625rem 1.3125rem",
      }}
    >
      <header>{header}</header>
      <main>{children}</main>
      <footer style={{ marginTop: "4rem" }}>
        © {new Date().getFullYear()},{" "}
        <a href={siteUrl} style={{ textDecoration: "none", color: "inherit" }}>
          {shortUrl}
        </a>
        {" | "}
        <Link to="/privacy-policy">Privacy Policy</Link>
        {" | "}
        <Link to="/cookie-policy">Cookie Policy</Link>
      </footer>
      <CookieConsent></CookieConsent>
    </div>
  )
}
